import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpContextToken,
} from "@angular/common/http";
import { HOTELS_API, ISSUE_TRACKER } from "./../../../../environments/server-endpoints.config";
import { LoginService } from "src/app/core/api-services/login.service";
export const REMOVE_LOCATION_ID = new HttpContextToken<boolean>(() => false);
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  API_URL: string;

  constructor(private loginService: LoginService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.API_URL = this.loginService.deploymentAPI;
    const authJWT = localStorage.getItem("token");
    let authReq: HttpRequest<any>;
    if (authJWT && !req.urlWithParams.startsWith(this.API_URL)) {
      if (req.urlWithParams.startsWith(HOTELS_API) || req.urlWithParams.startsWith(ISSUE_TRACKER)) {
        authReq = req.clone({
          headers: req.headers
            .set("Authorization", `Bearer ${authJWT}`)
            .set("Property", localStorage.getItem("locationId")),
        });
      } else {
        authReq = req.clone({
          headers: req.headers
            .set("Authorization", `Bearer ${authJWT}`)
        });
      }
    } else if (authJWT && req.urlWithParams.startsWith(this.API_URL)) {
      authReq = req.clone({
        headers: req.headers
          .set("Authorization", `Bearer ${authJWT}`)
          //.set("X-Auth-Token", "michael:1697748998030:d5f4f2c092be1c03054e7150873e1484")
          .set("Property", localStorage.getItem("locationId")),
        params: !req.url.includes('filesystem') && !req.url.includes('upload-base64') && !req.context.get(REMOVE_LOCATION_ID) ? req.params.set(
          "locationId",
          localStorage.getItem("legacyLocationId")
        ) : null,
      });
    } else {
      authReq = req.clone({ body: undefined });
    }
    return next.handle(authReq);
  }
}
