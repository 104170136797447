import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth-guard.service';
import { LogoutService } from './logout.service';
import { ReservationDetailsDataService } from './reservation-details.data-service';

@NgModule({
  imports: [
    CommonModule,
    // HttpClientModule
  ],
  declarations: [],
  providers: [
    AuthGuard,
    LogoutService,
    ReservationDetailsDataService,
  ]
})
export class DataServicesModule { }
