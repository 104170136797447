<div class="login-wrapper">
  <form class="login" [formGroup]="form" (ngSubmit)="onSubmit()">
    <section class="title">
      <h3 class="welcome">Welcome to</h3>
      The Faircloud Platform
      <h5 class="hint">Sign In to the dashboard</h5>
    </section>
    <div class="login-group">
      <input class="username" type="text" id="login_username" placeholder="Username" formControlName="username" required
        autofocus clrInput>
      <input class="password" type="password" id="login_password" placeholder="Password" formControlName="password"
        clrInput>
      <!-- <div class="checkbox">
        <input type="checkbox" id="rememberme">
        <label for="rememberme">Remember me</label>
      </div> -->
      <div class="error active" *ngIf="error">
        Invalid user name or password.
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!form.valid" [clrLoading]="loginState">Log In</button>

      <a class="signup" href="javascript://">Forgot Password?</a>
    </div>
    <div class="app-link">
      <div><p class="build">BUILD VERSION: Available after login.</p></div>
      <a href="https://apps.apple.com/us/app/faircloud/id1491676131" target="_blank">
        <img src="assets/images/iphone.png" class="img-responsive" width="150px" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=app.android.faircloud" target="_blank">
        <img src="assets/images/android.png" class="img-responsive" width="150px" />
      </a>
    </div>
   
  </form>

  <div class="floating-alert">
    <div class="alert alert-danger">
      <div class="alert-items">
        <div class="alert-item static" role="alert">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="error-standard"></cds-icon>
          </div>
          <span class="alert-text">
            1. URGENT: There have been several incidents of credit card fraud. During night shift some perpetrators will call and claim that they are calling from Support. They will request you to download TeamViewer or AnyDesk. Using teamviewer or anydesk, the caller will gain access to the system and give unauthorized credits. Please don't give access to team viewer, anydesk or any screen sharing tool to anyone who calls and ask for it.
            <br /><br />
  
            2. Avoid Chargebacks: Please don't extend OTA reservations, instead create new reservation if the guest wishes to extend reservation.
          </span>
        </div>
      </div>
    </div>
  </div>
</div>