import { Component, OnInit }  from '@angular/core';
import { Router, ActivatedRoute }     from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from './../core/api-services/login.service'

interface ParamsType {
  type: String;
  number?: string;
  token: string;
  location: string;
  employee: string;
  app: string
}

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  // Component used to redirect OLD PMS to this new UI
  // Params used- type, number, token, location, app, employee
  // http://localhost:4200/redirect?type=reservation&number=109134&token=bob.patel:1537223568335:77e385e1a3e5731eed3c75dec3ee2aa2&employee=c017d194600ea71a01600ecccc8b0009&app=somerset

  sessionId: Observable<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService) {
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(map(params => params["params"] as ParamsType)).subscribe(paramsRes=> {
        console.log("paramsRes ", paramsRes);
        // localStorage.setItem( 'legacyToken', paramsRes.token );
        localStorage.setItem('locationId', paramsRes.location);
        
        localStorage.setItem('appName', paramsRes.app);

        this.loginService.redirectApp(paramsRes.token, paramsRes.employee).subscribe(response=> {
          console.log("REDIRECT", response);
          if(paramsRes.type === 'reservation') {
            this.router.navigateByUrl(`${paramsRes.app}/reservation/${paramsRes.number}`);
          }
          if(paramsRes.type === 'pos') {
            this.router.navigateByUrl(`${paramsRes.app}/outlet/pos`);
          }
        });
      });
  }
}
