import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class ReservationDetailsDataService {
  public getReservation$: Observable<any>;
  getReservationSubject = new BehaviorSubject<any>({});

  public getLineItem$: Observable<any>;
  getLineItemSubject = new BehaviorSubject<any>(null);

  public getLineItemsPosted$: Observable<any>;
  getLineItemsPostedSubject = new BehaviorSubject<any>(null);

  public getLedger$: Observable<any>;
  getLedgerSubject = new BehaviorSubject<any>({});

  constructor() {
    this.getReservation$ = this.getReservationSubject.asObservable();
    this.getLineItem$ = this.getLineItemSubject.asObservable();
    this.getLineItemsPosted$ = this.getLineItemsPostedSubject.asObservable();
    this.getLedger$ = this.getLedgerSubject.asObservable();
  }

  checkAllLineITemsArePosted(data) {
    for (let i = 0; i < data.length; i++) {
      if (!data[i].postedForAudit && data[i].otherItems === 'Room Rate') {
          return false;
        } else {
        return true;
      }
    }
  }

}
