import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from './../../core/api-services/login.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import * as momentTimezone from 'moment-timezone';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HeaderService } from './../../core/api-services/header.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup;
  loginState: ClrLoadingState = ClrLoadingState.DEFAULT;
  error: boolean;
  navigation: any;
  navigationId: number;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private headerService: HeaderService,
  ) {
    this.form = new UntypedFormGroup({
      username: new UntypedFormControl(null, [
        Validators.required
      ]),
      password: new UntypedFormControl(null, [
        Validators.required
      ])
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      localStorage.clear();
    });
  }

  onSubmit() {
    console.log(this.form.value);
    this.loginState = ClrLoadingState.LOADING;
    this.loginService.getToken(this.form.value.username, this.form.value.password)
      .subscribe(response => {
        console.log(response);
        if (this.loginService.isLoggedIn) {
          const dayViewDate = momentTimezone.tz(response.timeZone).format('DD/MMM/YYYY');
          localStorage.setItem('dayViewDate', dayViewDate);
          this.getHotelDeploymentAPI(response.locationId);
        } else {
          this.error = true;
          this.loginState = ClrLoadingState.DEFAULT;
        }
      },
        (_error) => {
          this.loginState = ClrLoadingState.DEFAULT;
          this.snackBar.open('Incorrect Login Credentials, please try again.', 'Ok', { duration: 3500 });
        },
        () => this.loginState = ClrLoadingState.SUCCESS);
  }

  getHotelDeploymentAPI(locationId: number) {
    this.headerService.getHotelDeplomentAPI(locationId).subscribe(res => {
      if (res && res.length) {
        this.loginService.setDeploymentAPI(res[0].url);
        this.error = false;
        // const gatewayTerminalRule: any = response.gatewayTerminalRule;
        // localStorage.setItem('gatewayIntegrationRule', gatewayTerminalRule);
        this.loginState = ClrLoadingState.DEFAULT;
        this.router.navigate(['./app/reservation-list/inhouse']);
        this.router.events.subscribe((event: any) => {
          this.navigation = event;
          if (event instanceof NavigationEnd && (this.navigationId === undefined || this.navigationId !== this.navigation.id)) {
            this.navigationId = this.navigation.id;
          }
        });
      }
    });
  }
}
