import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './common/http-error-handler.service';
import { CurrentStatistics } from './../models/current-stats.model';

@Injectable()
export class StatisticsService {

  public currentStatistics$: Observable<any>;
  private currentStatistics = new BehaviorSubject<any>({});

  private handleError: HandleError;
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.currentStatistics$ = this.currentStatistics.asObservable();
    this.handleError = httpErrorHandler.createHandleError('StatisticsService');
   }

  getCurrentStatistics(forDate: string): Observable<CurrentStatistics> {
    const options = {params: new HttpParams().set('forDate', forDate)};
    return this.http.get<CurrentStatistics>('hotel_statistics/current_statistics', options)
    .pipe(map(res=>{
      this.currentStatistics.next(res);
      return res;
    }),
      catchError(this.handleError<any>('getCurrentStatistics', []))
    );
  }
}
