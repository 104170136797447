import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject }    from 'rxjs';

@Injectable()
export class LogoutService {
  constructor(
    private router: Router
  ) { }

  sessionActionDialogSubject = new Subject<boolean>();
  sessionActionDialog$ = this.sessionActionDialogSubject.asObservable();

  logout() {
    this.router.navigate(['login']);
    localStorage.clear();
  }
}
