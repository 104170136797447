import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from './common/http-error-handler.service';
import { ReservationDetailsDataService } from './../data-services/reservation-details.data-service';

import { ReservationId } from './../models/reservation.model';
import { RoomInventory } from './../models/room-inventory.model';
import { LineItemReservation } from './../models/line-item-reservation.model';
import { ResevationLedger } from './../models/reservation-ledger.model';
import { StayInformation } from './../models/stay-information.model';
import { SuccessResponse } from './../models/success-response.model';

@Injectable()
export class ReservationDetails {
  private handleError: HandleError;
  reservation: ReservationId;
  roomAssignmentWarning: boolean;

  private manuallyPosted = new Subject<any>();
  manuallyPosted$ = this.manuallyPosted.asObservable();

  private updateLedger = new Subject<any>();
  updateLedger$ = this.updateLedger.asObservable();

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private reservationDetailsDataService: ReservationDetailsDataService) {
    this.handleError = httpErrorHandler.createHandleError('ReservationDetails');
  }

  reservationManuallyPosted() {
    this.manuallyPosted.next(true);
  }


  updateReservationLedger() {
    this.updateLedger.next(true);
  }

  getReservationDetails(id?: string): Observable<ReservationId> {
    const options = { params: new HttpParams().set('id', id) };
    return this.http.get<ReservationId>('reservation/fetch_reservation', options)
      .pipe(
        tap(response => {
          this.reservation = response;
          this.reservationDetailsDataService.getReservationSubject.next(response);
          // this.reservationDetailsDataService.getReservationSubject.complete();
        }),
        catchError(this.handleError<any>('getReservationDetails', []))
      );
  }

  getLineItemReservation(id: any, offset: string, limit: string): Observable<LineItemReservation[]> {
    const options = { params: new HttpParams().set('reservationId', id).set('offset', offset).set('limit', limit) };
    return this.http.get<LineItemReservation[]>('line_item_reservation_intersection/list_pagination', options)
      .pipe(
        tap(response => {
          this.reservationDetailsDataService.getLineItemSubject.next(response);
          this.reservationDetailsDataService.getLineItemsPostedSubject
            .next(this.reservationDetailsDataService.checkAllLineITemsArePosted(response));
          this.reservationDetailsDataService.getLineItemsPostedSubject.complete();

        }),
        catchError(this.handleError<any>('getLineItemReservation', []))
      );
  }

  getRoomInventory(roomType: string, startDate: string, endDate: string): Observable<RoomInventory[]> {
    const options = { params: new HttpParams().set('roomType', roomType).set('startDate', startDate).set('endDate', endDate) };
    return this.http.get<RoomInventory[]>('room_inventory/list', options)
      .pipe(
        catchError(this.handleError<any>('getRoomInventory', []))
      );
  }

  updateRoomAssignment(roomNumber: string): Observable<RoomInventory[]> {
    const options = { params: new HttpParams().set('roomNumber', roomNumber).set('reservationId', this.reservation.id.toString()) };
    return this.http.post<RoomInventory[]>('reservation/assign_room', {}, options)
      .pipe(
        catchError(this.handleError<any>('updateRoomAssignment', []))
      );
  }

  removeRoomAssignment(): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', this.reservation.id.toString()) };
    return this.http.post<any>('reservation/unassign_room_for_unposted_line_items', {}, options)
      .pipe(
        catchError(this.handleError<any>('removeRoomAssignment', []))
      );
  }

  removeLineItem(liriId?: string): Observable<any> {
    const options = { params: new HttpParams().set('liriId', liriId) };
    return this.http.post<any>('line_item_reservation_intersection/unpost_line_item', {}, options)
      .pipe(
        catchError(this.handleError<any>('removeLineItem', []))
      );
  }

  getReservationLedger(id: number): Observable<ResevationLedger> {
    const options = { params: new HttpParams().set('reservationId', id.toString()) };
    return this.http.get<ResevationLedger>('ledger/reservation_ledger', options)
      .pipe(
        tap(response => {
          this.reservationDetailsDataService.getLedgerSubject.next(response);
          // this.reservationDetailsDataService.getLedgerSubject.complete();
        }),
        catchError(this.handleError<any>('getReservationLedger', []))
      );
  }

  updateWriteOff(writeOffAmount): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id.toString())
        .set('writeOffAmount', writeOffAmount)
    };
    return this.http.post<any>('reservation/add_write_off', {}, options)
      .pipe(
        catchError(this.handleError<any>('updateWriteOff', []))
      );
  }

  addComments(payload): Observable<any> {
    payload.reservationId = this.reservation.id;
    const options = {};
    return this.http.post<any>('reservation/add_reservation_comments', payload, options)
      .pipe(
        catchError(this.handleError<any>('addComments', []))
      );
  }

  addSpecialRequest(payload): Observable<any> {
    payload.reservationId = this.reservation.id;
    const options = {};
    return this.http.post<any>('reservation/add_reservation_special_request', payload, options)
      .pipe(
        catchError(this.handleError<any>('addSpecialRequest', []))
      );
  }

  addLineItem(payload): Observable<any> {
    payload.reservationId = this.reservation.id;
    const options = {};
    return this.http.post<any>('reservation/add_new_line_item', payload, options)
      .pipe(
        catchError(this.handleError<any>('addLineItem', []))
      );
  }

  getStayInformation(reservationId): Observable<StayInformation> {
    const options = {
      params: new HttpParams()
        .set('reservationId', String(reservationId))
    };
    return this.http.get<StayInformation>('line_item_reservation_intersection/stay_information_for_reservation', options)
      .pipe(
        catchError(this.handleError<any>('getStayInformation', []))
      );
  }

  getRoomInhouseList(): Observable<any> {
    // const options = {params: new HttpParams()
    //   .set('reservationId', reservationId.toString())};
    return this.http.get<any>('reservation/inhouse', {})
      .pipe(
        catchError(this.handleError<any>('getInhouseList', []))
      );
  }

  // GET api fixed. It sends full reservation details along with updated field.
  updateReservation(payload): Observable<any> {
    const options = {};
    return this.http.post<any>('reservation/update_reservation_details', payload, options)
      .pipe(
        catchError(this.handleError<any>('updateReservation', []))
      );
  }

  updateArrivalTime(startDate): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id.toString())
        .set('startDate', startDate)
    };
    return this.http.post<any>('reservation/update_reservation_arrival_time', {}, options)
      .pipe(
        catchError(this.handleError<any>('updateArrivalTime', []))
      );
  }



  updateGuest(payload): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id.toString())
    };
    return this.http.post<any>('guest/update_guest', payload, options)
      .pipe(
        catchError(this.handleError<any>('updateGuest', []))
      );
  }



  makeReservationNonTaxable(payload): Observable<any> {
    const options = {};
    return this.http.post<any>('reservation/make_reservation_non_taxable', payload, options)
      .pipe(
        catchError(this.handleError<any>('makeReservationNonTaxable', []))
      );
  }

  makeReservationTaxable(payload): Observable<any> {
    const options = {};
    return this.http.post<any>('reservation/make_reservation_taxable', payload, options)
      .pipe(
        catchError(this.handleError<any>('makeReservationNonTaxable', []))
      );
  }

  noShowReservationStatus() {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id)
    };
    return this.http.post<any>('reservation/no_show_reservation_status', {}, options)
      .pipe(
        catchError(this.handleError<any>('noShowReservationStatus', []))
      );
  }





  emailReservation(ccForm?: boolean): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id.toString())
        .set('ccForm', ccForm.toString())
    };
    return this.http.get<any>('reservation/email_reservation', options)
      .pipe(
        catchError(this.handleError<any>('emailReservation', []))
      );
  }

  emailInvoice(ccForm?: boolean): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id.toString())
        .set('ccForm', ccForm.toString())
    };
    return this.http.get<any>('reservation/email_invoice', options)
      .pipe(
        catchError(this.handleError<any>('emailInvoice', []))
      );
  }

  manuallyPostLineItem(): Observable<any> {
    const options = {
      params: new HttpParams()
        .set('reservationId', this.reservation.id.toString())
    };
    return this.http.post<any>('reservation/manually_post_line_item', {}, options)
      .pipe(
        catchError(this.handleError<any>('manuallyPostLineItem', []))
      );
  }


  getLineItemList(reservationID): Observable<any> {
    const options = { params: new HttpParams().set('id', reservationID) };
    return this.http.get<any>('line_items/list_for_reservation', options)
      .pipe(
        catchError(this.handleError<any>('getLineItemList', []))
      );
  }

  getReservationPDF(): Observable<SuccessResponse> {
    const options = { params: new HttpParams().set('reservationId', this.reservation.id.toString()) };
    return this.http.get<SuccessResponse>('reservation/regenerate_reservation_pdf', options)
      .pipe(
        catchError(this.handleError<any>('getReservationPDF', []))
      );
  }

  getReservationInvoicePDF(): Observable<SuccessResponse> {
    const options = { params: new HttpParams().set('reservationId', this.reservation.id.toString()) };
    return this.http.get<SuccessResponse>('reservation/regenerate_invoice_pdf', options)
      .pipe(
        catchError(this.handleError<any>('getReservationPDF', []))
      );
  }

  getCardTransactions(reservationId): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', reservationId) };
    return this.http.get<any>('shift4/list_for_reservation', options)
      .pipe(
        catchError(this.handleError<any>('getCardTransactions', []))
      );
  }

  getAllTransactions(reservationId): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', reservationId).set('offset', '0').set('limit', '100') };
    return this.http.post<any>('transactions/reservation/list_pagination', {}, options)
      .pipe(
        catchError(this.handleError<any>('getAllTransactions', []))
      );
  }

  postFirstNightCharges(reservationId): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', reservationId) };
    return this.http.post<any>('v2/reservation/post_first_night_on_cancelled_or_no_show_reservation', {}, options)
      .pipe(
        catchError(this.handleError<any>('postFirstNightCharges', []))
      );
  }

  reverseCheckOutReservation(reservation): Observable<any> {
    return this.http.post<any>('reservation/reverse_check_out_reservation', reservation)
      .pipe(
        catchError(this.handleError<any>('reverseCheckOutReservation', []))
      );
  }

  reverseCheckInReservation(reservation): Observable<any> {
    return this.http.post<any>('reservation/reverse_check_in_reservation', reservation)
      .pipe(
        catchError(this.handleError<any>('reverseCheckInReservation', []))
      );
  }

  reverseCancelReservation(reservation): Observable<any> {
    return this.http.post<any>('reservation/reverse_cancel_reservation', reservation)
      .pipe(
        catchError(this.handleError<any>('reverseCancelReservation', []))
      );
  }

  earlyCheckOutReservation(reservation): Observable<any> {
    return this.http.post<any>('reservation/early_checkout_reservation', reservation)
      .pipe(
        catchError(this.handleError<any>('earlyCheckOutReservation', []))
      );
  }

  reverseNoShowReservationStatus(reservationId): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', reservationId) };
    return this.http.post<any>('reservation/reverse_no_show_reservation_status', {}, options)
      .pipe(
        catchError(this.handleError<any>('reverseNoShowReservationStatus', []))
      );
  }

  markCreditCardInvalid(reservationId): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', reservationId) };
    return this.http.post<any>('reservation/mark_credit_card_invalid', {}, options)
      .pipe(
        catchError(this.handleError<any>('markCreditCardInvalid', []))
      );
  }

  releaseRoomInventoryForCancelledReservation(reservationId): Observable<any> {
    const options = { params: new HttpParams().set('reservationId', reservationId) };
    return this.http.post<any>('v2/reservation/release_room_inventory_for_cancelled_reservation', {}, options)
      .pipe(
        catchError(this.handleError<any>('releaseRoomInventoryForCancelledReservation', []))
      );
  }

  assignAndCheckInReservation(reservation): Observable<any> {
    return this.http.post<any>('reservation/assign_and_checkin_reservation', reservation)
      .pipe(
        catchError(this.handleError<any>('assignAndCheckInReservation', []))
      );
  }

  sendEmailMessage(payload): Observable<any> {
    return this.http.post<any>('reservation/custom_email', payload)
      .pipe(
        catchError(this.handleError<any>('sendEmailMessage', []))
      );
  }
}
