// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_gql: 'https://gw.faircloudpms.com/graphql', 
  issueTracker_image_server: 'http://asmi.server1430.com:8082',
  smart_rate: 'http://rate-shop-alb-1616000489.us-east-2.elb.amazonaws.com/',
  container: 'http://sf1-javabe.server1430.com:5056/',
  email_api: 'https://gw.faircloudpms.com/mailservice/api/',
  booking_server: 'http://expediaplus.server1430.com:8080/',
  email_account: 'https://gw.faircloudpms.com/emailservice/api/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
