import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/core/api-services/login.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  API_URL:string;

  constructor(private loginService: LoginService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.API_URL = this.loginService.deploymentAPI;
    const apiReq = req.clone({ url: `${this.API_URL}/${req.url}` });
    if(req.url.includes('http') || req.url.includes('assets/data')) {
      return next.handle(req);
    } else {
      return next.handle(apiReq);
    }
  }
}