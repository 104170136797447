export const SERVERS = 'faircloudws';
export const LOGIN_API = 'https://gw.faircloudpms.com/fcauth/v1/';
export const BANQUET_API = 'https://gw.faircloudpms.com/banquet/api/';
export const CENTRAL_API = 'https://gw.faircloudpms.com/customer/api/';
export const HOTELS_API = 'https://gw.faircloudpms.com/hotels/';
// export const HOTELS_API = 'https://gw.faircloudpms.com/hotels/';
export const TAX_API = 'https://gw.faircloudpms.com/tax-profile/api/';
export const BILLING_API = 'http://54.183.238.86:8080/api/';
export const COMMUNICATION_API = 'https://gw.faircloudpms.com/communication/api/';
export const DIRECT_BILL_LEADS_API = 'http://13.57.58.151:8011/directbillleads/api/';
export const CONTRACT_API = 'http://13.57.58.151:8040/contract/api/';
export const KEY_VALUE_API = 'http://13.57.58.151:8100/keyvalue/api/';
export const HOTEL_STATISTICS_API = 'http://hotel-stats-lb-354391266.us-west-2.elb.amazonaws.com/';
export const EXPEDIA_API = 'http://expediaplus.server1430.com:8080/expedia/';
export const FC_CLIENT_API = 'http://ec2-35-163-193-108.us-west-2.compute.amazonaws.com:8080/fc-clients/v1/';
export const REVENUE_CALL = 'http://hotels.us-west-2.elasticbeanstalk.com/hotel/api/';
export const ISSUE_TRACKER = 'https://gw.faircloudpms.com/issue-tracker/api/';
export const NOTES_API = 'http://tn.server1430.com:8050/api/';
export const CHANNEL_MANAGER = 'https://gw.faircloudpms.com/cm/v1/';
export const BOOKING_QC = 'https://gw.faircloudpms.com/bookingqc/';
export const FILE_SYSTEM = 'https://gw.faircloudpms.com/filesystem/';
export const EXPEDIA_SYSTEM_API = 'https://gw.faircloudpms.com/expedia/';
export const BOOKINGQC_SYSTEM_API = 'https://gw.faircloudpms.com/bookingqc/'
