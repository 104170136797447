import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-interceptor';
import { APIInterceptor } from './api-interceptor';
// import { CachingInterceptor } from './caching-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
//   { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true }
  // ,
];
