import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { LoginService } from './login.service';
import { StatisticsService } from './statistics.service';
import { ReservationDetails } from './reservation-details.service';
import { httpInterceptorProviders } from './http-interceptors/index';
import { HeaderService } from './header.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers: [
    LoginService,
    StatisticsService,
    ReservationDetails,
    HeaderService,
    httpInterceptorProviders
  ]
})
export class ApiServicesModule { }
