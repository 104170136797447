import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './common/http-error-handler.service';
import { Login } from './../models/login.model';
import { LOGIN_API } from './../../../environments/server-endpoints.config';
import jwt_decode from 'jwt-decode';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class LoginService {
  redirectUrl: string;
  isLoggedIn: boolean = false;
  private handleError: HandleError;

  public deploymentAPISubject: BehaviorSubject<string> = new BehaviorSubject(null);
  public deploymentAPI$ = this.deploymentAPISubject.asObservable().pipe(distinctUntilChanged());
  public get deploymentAPI() { return this.deploymentAPISubject.value || localStorage.getItem('deploymentAPI');}

  setDeploymentAPI(api: string) {
    this.deploymentAPISubject.next(api);
    localStorage.setItem('deploymentAPI', api);
  }

  constructor(
    private http: HttpClient,
    
    httpErrorHandler: HttpErrorHandler
  ) { this.handleError = httpErrorHandler.createHandleError('LoginService'); }

  getToken(username: string, password: string): Observable<Login> {
    const body = {
      username, password
    };
   
    return this.http.post<Login>(LOGIN_API + 'user/login', body, {})
    .pipe(
      tap(res => {
        if(!res.hasOwnProperty('error') && res.token) {
          localStorage.setItem( 'token', res.token );
          // localStorage.setItem('legacyLocationId', res.legacyLocationId)
          localStorage.setItem('timeZone', res.timeZone);
          localStorage.setItem('loggedInUser',res.userName);
          localStorage.setItem('locationId', String(res.locationId));
          this.setDeploymentAPI(res.oldPmsEndpoint);
          // res.user.primaryLocation ? localStorage.setItem('currencyCode', res.user.primaryLocation.currencyCode) : null;
          const JWT_DATA:any = jwt_decode(res.permissions);
          localStorage.setItem('permissions',JSON.stringify(JWT_DATA['scopes']));
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      }),
      catchError(this.handleError<any>('getToken', []))
    );
  }

  redirectApp(token, employeeId) {
    const options = {
      params: new HttpParams().set('employeeId', employeeId),
      headers: new HttpHeaders().set('X-Auth-Token', token)
    };
    return this.http.get<Login>('authenticate/user-data', options)
    .pipe(
      tap(res => {
        if(!res.hasOwnProperty('error')) {
          localStorage.setItem('locationId',res['legacyLocationId']);
          localStorage.setItem('employee',JSON.stringify(res['user']));
          // if (Object.keys(res.roleMap).length > 0 && res.roleMap.constructor === Object) {
          //   localStorage.setItem('permissions',JSON.stringify(res['roleMap']));
          // }
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      }),
      catchError(this.handleError<any>('redirectApp', []))
    );
  }
}
