import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpErrorHandler, HandleError } from './common/http-error-handler.service';

import { Location } from './../models/location.model';
import { BOOKINGQC_SYSTEM_API, EXPEDIA_SYSTEM_API, HOTELS_API } from '../../../environments/server-endpoints.config';

@Injectable()
export class HeaderService {
  private handleError: HandleError;
  public employeeLocations: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.handleError = httpErrorHandler.createHandleError('HeaderService');
  }

  getMenuItems(): Observable<any> {
    const options = {};
    return this.http.get<any>('/assets/data/menu-items.json', options)
      .pipe(
        catchError(this.handleError<any>('getMenuItems', []))
      );
  }

  // Header drowdown locations
  getEmployeeLocations(): Observable<Location[]> {
    const options = {};
    return this.http.get<Location[]>(HOTELS_API + 'hotel/getAllHotels', options)
      .pipe(
        tap(res => {
          if(res && res.length > 0) {
            this.employeeLocations.next(res);
          }
        }),
        catchError(this.handleError<any>('getEmployeeLocations', []))
      );
  }

  getHotelDeplomentAPI(hotelId: number) {
    const options = {};
    return this.http.get<any>(HOTELS_API + 'deployment/hotel/' + hotelId, options)
      .pipe(
        catchError(this.handleError<any>('getHotelDeplomentAPI', []))
      );
  }

  fetchLocationLayoutForSelectedProperty() {
    return this.http.get<any>('location_layout_settings/list')
      .pipe(
        catchError(this.handleError<any>('fetchLocationLayoutForSelectedProperty', []))
      );
  }

  saveOrUpdateReservationMandatory(settings) {
    return this.http.post<any>('location_layout_settings/save_or_update', settings)
      .pipe(
        catchError(this.handleError<any>('saveOrUpdateReservationMandatory', []))
      );
  }

  pullReservations(noOfDays, status) {
    return this.http.post<any>(EXPEDIA_SYSTEM_API + 'reservations/pullAll', {status, pendingDays: noOfDays})
      .pipe(
        catchError(this.handleError<any>('pullReservations', []))
      );
  }
  pullBookingQcReservations(fromDate,hotelId) {
    return this.http.post<any>(BOOKINGQC_SYSTEM_API + `reservations/pull-changed/${hotelId}?fromDate=${fromDate}`, {})
      .pipe(
        catchError(this.handleError<any>('pullBookingQcReservations', []))
      );
  }




}
