import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { NgModule } from "@angular/core";
import { environment } from "./../environments/environment";

const uri = environment.api_gql;

export function createApollo(httpLink: HttpLink) {
  const http = httpLink.create({ uri });

  const auth = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    const locationId = localStorage.getItem("locationId");
    if (!token) {
      return { headers: { ...headers } };
    } else {
      if (locationId) {
        return {
          headers: {
            ...headers,
            locationId: locationId,
            Authorization: `Bearer ${token}`,
          },
        };
      }
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  return {
    link: auth.concat(http),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
